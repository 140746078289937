import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import {AccordionList} from "./AccordionList";

const ABOUT_US_SUMMARY = 'about us';
const CONTACT_US_SUMMARY = 'get in touch';

const ABOUT_US_CONTENT = 'We bring together the latest breakthroughs in technology and mathematics' +
    ' to deliver consistent alpha to the financial markets. Placing integrity and risk management first, ' +
    'we aim to systematically challenge the status quo in all aspects of our business.';
const CONTACT_US_CONTENT = 'Interested in working with us or have questions?\n' +
    'Send inquiries to info@pineberry.tech\n';

export const PopulatedAccordionList: React.FC = () => {
    return (
        <AccordionList itemSummaries={[ABOUT_US_SUMMARY, CONTACT_US_SUMMARY]}
                       itemContents={[ABOUT_US_CONTENT, CONTACT_US_CONTENT]}/>
    );
}