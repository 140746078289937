import React from 'react';
import {PopulatedAccordionList} from "./PopulatedAccordionList";
import faded_green_pb_logo from './pb_green.svg';

export const App: React.FC = () => {
    return (
        <div className="App" tabIndex={0}>
            <img src={faded_green_pb_logo} loading="lazy" alt="large faded green pineberry logo"
                 style={{
                     position: 'absolute',
                     left: 'auto',
                     top: 'auto',
                     right: '25px',
                     bottom: '0%',
                     zIndex: -1,
                     overflow: 'visible',
                     maxHeight: '80%',
                     maxWidth: '80%'
                 }}
            />
            <div
                 style={{
                     height: 'auto',
                     width: '100%',
                     fontSize: '12.5vw',
                     textAlign: 'center',
                     lineHeight: '100%'
                 }}>
                PINEBERRY TECH
            </div>
            <PopulatedAccordionList/>
        </div>
    );
}