import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {adaptV4Theme, CssBaseline} from '@mui/material';
import "./index.css";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const colorTheme = createTheme(adaptV4Theme({
    palette: {
        background: {
            default: "#f1efe6"
        },
    },
    typography: {
        fontFamily: "Manrope"
    }
}));

root.render(
    <React.StrictMode>
        <ThemeProvider theme={colorTheme}>
            <CssBaseline/>
            <Router>
                <App/>
            </Router>
        </ThemeProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
