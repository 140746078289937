import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

const CONTENT_FONT_STYLE = {fontSize: '35px', fontWeight: 400};
export const AccordionList: React.FC<{ itemSummaries: string[], itemContents: string[] }>
    = ({itemSummaries, itemContents}) => {
    return (
        <div style={{padding:'25px'}}>
            {itemSummaries.map((itemSummary, index) => {
                return (
                    <Accordion style={{
                        backgroundColor: 'transparent',
                        borderTop: '2px solid',
                        borderBottom: (index === itemSummaries.length - 1) ? '2px solid': 'none',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderRadius: 0,
                        boxShadow: 'none'
                    }} disableGutters>
                        <AccordionSummary
                            expandIcon={<ExpandMore style={{fill: 'black', transform: 'scale(2)'}}/>}
                            id={`${index.toString()}`}
                        >
                            <Typography style={CONTENT_FONT_STYLE}>&lt; {itemSummary} &gt;</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{whiteSpace: 'pre-wrap', ...CONTENT_FONT_STYLE}} >
                                {itemContents.at(index)}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })
            }
        </div>
    );
}